/* eslint-disable max-classes-per-file */
import { SelectItem } from '@/types/select-item';

export class WsusConfig {
  id?: number;

  server_group_id?: number;

  is_managed?: boolean;

  server_url?: string;

  created_at?: string;

  updated_at?: string;
}

export class DeviceGroup {
  id?: number;

  organization_id?: number;

  name?: string;

  refresh_interval?: number;

  parent_server_group_id?: number;

  ui_color?: string;

  notes?: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  enable_os_auto_update?: any;

  server_count?: number;

  wsus_config?: WsusConfig;

  policies?: number[];

  constructor(init: Partial<DeviceGroup>) {
    Object.assign(this, init);
  }
}

export class DeviceGroupWithHierarchy extends DeviceGroup {
  children?: DeviceGroup[];

  depth?: number;

  has_subgroups?: boolean;

  parent_labels?: string[];
}

export enum OsAutoUpdateOption {
  KeepDeviceSetting,
  Disable,
  Enable,
}

export enum WsusOption {
  KeepDeviceSetting,
  WindowsUpdate,
  WSUS,
}

export function osAutoUpdateOptionToApiValue(
  enumValue: OsAutoUpdateOption,
): null | boolean {
  switch (enumValue) {
    case OsAutoUpdateOption.Disable:
      return false;
    case OsAutoUpdateOption.Enable:
      return true;
    case OsAutoUpdateOption.KeepDeviceSetting:
    default:
      return null;
  }
}

export function osAutoUpdateOptionToUiValue(
  apiValue: boolean | null,
): OsAutoUpdateOption {
  switch (apiValue) {
    case false:
      return OsAutoUpdateOption.Disable;
    case true:
      return OsAutoUpdateOption.Enable;
    case null:
    default:
      return OsAutoUpdateOption.KeepDeviceSetting;
  }
}

export function wsusOptionToApiValue(enumValue: WsusOption): boolean | null {
  switch (enumValue) {
    case WsusOption.WindowsUpdate:
      return false;
    case WsusOption.WSUS:
      return true;
    case WsusOption.KeepDeviceSetting:
    default:
      return null;
  }
}

export function wsusOptionToUiValue(
  apiValue: boolean | null | undefined,
): WsusOption {
  switch (apiValue) {
    case false:
      return WsusOption.WindowsUpdate;
    case true:
      return WsusOption.WSUS;
    case null:
    default:
      return WsusOption.KeepDeviceSetting;
  }
}

/**
 * Generate a list of interval options
 * @param {number} minHours the minimum interval value
 * @param {number} maxHours the maximum interval value
 * @returns Returns an array of intervals for a scan interval v-select component
 */
export function generateScanIntervalOptions(
  minHours: number,
  maxHours: number,
) {
  const options: { text: string; value: number }[] = [];
  // eslint-disable-next-line no-plusplus
  for (let i = minHours; i <= Number(maxHours); i++) {
    options.push({ text: `${i} Hours`, value: i * 60 });
  }
  return options;
}

export const scanIntervalOptions = generateScanIntervalOptions(4, 24);

export const osPatchOptions: SelectItem[] = [
  {
    text: "Keep Device's Setting",
    value: OsAutoUpdateOption.KeepDeviceSetting,
  },
  { text: 'Disable OS Automatic Updates', value: OsAutoUpdateOption.Disable },
  { text: 'Enable OS Automatic Updates', value: OsAutoUpdateOption.Enable },
];

export const wsusOptions: SelectItem[] = [
  { text: "Keep Device's Setting", value: WsusOption.KeepDeviceSetting },
  { text: 'Windows Update', value: WsusOption.WindowsUpdate },
  { text: 'WSUS', value: WsusOption.WSUS },
];
