var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ax-card',{staticClass:"ax-add-devices-modal"},[_c('v-card-title',{staticClass:"tw-flex tw-justify-between tw-uppercase"},[_c('span',[_vm._v("Assign Devices to Group")]),_c('ax-button',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close-modal')}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiClose))])],1)],1),_c('v-card-text',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('span',{staticClass:"tw-font-bold",attrs:{"data-test-id":"selected-devices-count"}},[_vm._v("Selected ("+_vm._s(_vm.selectedDevices.length)+")")])]),_c('v-col',{attrs:{"cols":"8"}},[_c('ax-combobox',{staticClass:"mb-0",attrs:{"items":_vm.searchSuggestions,"menu-props":{ closeOnContentClick: true },"label":"Search/Filter Devices","prepend-inner-icon":_vm.mdiMagnify,"chips":"","clearable":"","hide-details":"","hide-selected":"","multiple":""},on:{"change":_vm.onSearchChange},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [_c('ax-chip',_vm._b({attrs:{"input-value":selected}},'ax-chip',attrs,false),[_c('span',{staticClass:"tw-pr-2 tw-truncate",attrs:{"title":item === Object(item) ? item.text : item}},[_vm._v(" "+_vm._s(item === Object(item) ? item.text : item)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v(_vm._s(_vm.mdiCloseCircle))])],1)]}}])})],1)],1),(_vm.devices && _vm.deviceGroupsById)?_c('ax-table',{staticClass:"tw-my-6 table-container",attrs:{"data-test-id":"group-editor-assign-devices-table","footer-props":_vm.defaultDevicesTableFooter,"headers":_vm.addDevicesToGroupHeaders,"items":_vm.devices,"options":_vm.tableState,"server-items-length":_vm.totalDevices,"column-select":false,"must-sort":"","item-key":"id","no-data-text":"No devices","show-select":""},on:{"update:options":function($event){_vm.tableState=$event}},scopedSlots:_vm._u([{key:"item.os_family",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"tw-flex tw-justify-center"},[_c('ax-os-icon',{attrs:{"os-family":item.os_family}})],1)]}},{key:"item.display_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.display_name)+" ")]}},{key:"item.server_group_id",fn:function(ref){
var item = ref.item;
return [_c('ax-device-group-column',{attrs:{"group":_vm.deviceGroupsById[item.server_group_id],"organization-id":item.organization_id,"no-link":""}})]}},{key:"item.tags",fn:function(ref){
var item = ref.item;
return [_c('v-lazy',[_c('ax-device-tags',{staticClass:"text-left",attrs:{"tags":item.tags}})],1)]}},{key:"item.ip_addrs",fn:function(ref){
var item = ref.item;
return [_c('ax-ip-address-column',{attrs:{"ips":{
            publicAddresses: item.ip_addrs,
            privateAddresses: item.ip_addrs_private,
          },"no-data-text":"No IP Found"}})]}},{key:"item.os_version",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatOSVersion")(item))+" ")]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('ax-device-status-column',{attrs:{"device":item}})]}}],null,false,611036271),model:{value:(_vm.selectedDevices),callback:function ($$v) {_vm.selectedDevices=$$v},expression:"selectedDevices"}}):_vm._e()],1),_c('v-card-actions',{staticClass:"tw-justify-end tw-px-6 tw-pb-4"},[_c('ax-button',{attrs:{"mode":"secondary"},on:{"click":function($event){return _vm.$emit('close-modal')}}},[_vm._v("Cancel")]),_c('ax-button',{attrs:{"mode":"primary","disabled":_vm.selectedDevices.length < 1},on:{"click":_vm.assignDevices}},[_vm._v(" Assign Devices To Group ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }